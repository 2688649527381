import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationHistoryService {
  @AutoDestroy private destroy$: Subject<void> = new Subject<void>();
  private history: string[] = [window.location.pathname];
  private isPopState = false;

  constructor(router: Router, location: PlatformLocation) {
    location.onPopState(() => {
      this.history.pop();
      this.isPopState = true;
    });

    router.events
      .pipe(
        distinctUntilChanged(),
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        if (this.isPopState) {
          this.isPopState = false;
          return;
        }
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects); // Store each URL in history array
        }
      });
  }

  // Get full history
  public getHistory(): string[] {
    return this.history;
  }

  // Get Previous Url
  public get previousUrl(): string {
    if (this.history?.length > 1) {
      // using -2 index because -1 will be current url
      return this.history[this.history?.length - 2];
    }
    return this.history[0];
  }

  // Clear history
  public clearHistory() {
    this.history = [];
  }
}
